import React, { Fragment, useState, useEffect } from "react"
import fb from "../../images/facebook.svg"
import ig from "../../images/instagram.svg"
import styles from "./navBarStyles.module.scss"
import Link from "../Link/Link"
import Button from "../Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import MobileNav from "../MobileNav/MobileNav"
import NavLink from "../NavLink/NavLink"

const NavBar = props => {
  const [renderMobile, setRenderMobile] = useState(false)
  const [showMobile, setShowMobile] = useState(false)

  useEffect(() => {
    let subscribed = true
    if (renderMobile) {
      setShowMobile(true)
    }
    return () => {
      subscribed = false
    }
  }, [renderMobile])

  useEffect(() => {
    let subscribed = true
    if (!showMobile) {
      setTimeout(() => {
        if (subscribed) {
          setRenderMobile(false)
        }
      }, 201)
    }
    return () => {
      subscribed = false
    }
  }, [showMobile])

  const handleToggleMobile = () => {
    if (showMobile && renderMobile) {
      setShowMobile(false)
    }
    if (!showMobile && !renderMobile) {
      setRenderMobile(true)
    }
  }
  return (
    <Fragment>
      {renderMobile ? (
        <MobileNav show={showMobile} exit={handleToggleMobile}></MobileNav>
      ) : null}
      <div className={styles.wrapper}>
        <button className={styles.barsWrapper} onClick={handleToggleMobile}>
          <FontAwesomeIcon icon={faBars}></FontAwesomeIcon>
        </button>
        <div className={styles.leftWrapper}>
          <NavLink to="/about">ABOUT</NavLink>
          <NavLink to="/products">PRODUCTS</NavLink>
        </div>
        <div className={styles.logoWrapper}>
          <Link to="/">
            <img
              src="https://storage.googleapis.com/health-portal/public/logo-no-tag.svg"
              alt="Kindbio Logo"
              style={{ padding: "0", margin: "0" }}
              height={38}
            ></img>
          </Link>
        </div>
        <div className={styles.rightWrapper}>
          <NavLink to="/blog">BLOG</NavLink>
          {/*<NavLink to="/faq">FAQ</NavLink>*/}
          <NavLink to="/contact">CONTACT</NavLink>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <a
              href="https://www.instagram.com/kind_bio/?hl=en"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={ig}
                height={20}
                style={{ marginRight: "15px" }}
                alt="Instagram Icon"
              ></img>
            </a>
            <a
              href="https://www.facebook.com/KindBioCanna"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fb} height={20} alt="Facebook Icon"></img>
            </a>
          </div>
          {/*
          <div style={{ textAlign: "center" }}>
            <div>
              <Link style={{ fontSize: "0.7em" }}>CREATE ACCOUNT</Link>
            </div>
            <Button compact style={{ fontSize: "0.7em" }}>
              LOGIN
            </Button>
          </div>*/}
        </div>
      </div>
    </Fragment>
  )
}

export default NavBar
