import React from "react"

import styles from "./buttonStyles.module.scss"

const Button = ({
  children,
  onClick,
  className,
  style,
  compact,
  name,
  type,
  disabled,
}) => {
  return (
    <button
      style={{ ...style }}
      name={name}
      className={[
        styles.button,
        className,
        compact ? styles.compact : null,
      ].join(" ")}
      onClick={onClick}
      type={type || "button"}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

export default Button
