import React from "react"
import NavLink from "../NavLink/NavLink"
import styles from "./mobileNavStyles.module.scss"
import logo from "../../images/logo-no-tag.inline.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"
import fb from "../../images/facebook.svg"
import ig from "../../images/instagram.svg"
import Link from "../Link/Link"
const MobileNav = ({ show, exit }) => {
  return (
    <div
      className={[styles.wrapper, show ? styles.show : styles.hide].join(" ")}
    >
      <div style={{ textAlign: "right", padding: "10px" }}>
        <button
          className={styles.exitButton}
          onClick={() => {
            exit()
          }}
        >
          <FontAwesomeIcon icon={faTimes} size="2x"></FontAwesomeIcon>
        </button>
      </div>
      <div style={{ textAlign: "center" }}>
        <Link to="/">
          <img src={logo} alt="Kindbio Logo" width={220}></img>
        </Link>
      </div>
      <div className={styles.navLinks}>
        {["ABOUT", "PRODUCTS", "BLOG", "CONTACT"].map(page => (
          <NavLink
            key={page}
            style={{ marginTop: "20px" }}
            to={`/${page.toLowerCase()}`}
          >
            {page}
          </NavLink>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <a
          href="https://www.instagram.com/kind_bio/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={ig}
            height={20}
            style={{ marginRight: "15px" }}
            alt="Instagram Icon"
          ></img>
        </a>
        <a
          href="https://www.facebook.com/KindBioCanna"
          target="_blank"
          rel="noreferrer"
        >
          <img src={fb} height={20} alt="Facebook Icon"></img>
        </a>
      </div>
    </div>
  )
}

export default MobileNav
