import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styles from "./linkStyles.module.scss"

const Link = ({
  to,
  alt,
  children,
  activeStyle,
  activeClassName,
  partiallyActive,
  style,
  className,
  reverseUnderline,
}) => {
  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeStyle={activeStyle}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={[
          styles.link,
          className,
          reverseUnderline ? styles.reverseUnderline : null,
        ].join(" ")}
        style={{ ...style }}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a
      href={to}
      alt={alt}
      className={[styles.link, className].join(" ")}
      style={{ ...style }}
    >
      {children}
    </a>
  )
}

export default Link
