import React from "react"
import Link from "../Link/Link"
import styles from "./navLinkStyles.module.scss"
const NavLink = ({ to, children, style }) => {
  return (
    <Link className={styles.navLink} to={to} style={{ ...style }}>
      {children}
    </Link>
  )
}

export default NavLink
